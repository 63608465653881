const SEVER_URL = 'https://api.qingcigame.com';
// const WEB_PROFIX = '/qcgame/i18n';
const WEB_PROFIX = '';
const NAV_KEYS = ['INDEX', 'INFO', 'PRODUCT', 'NOTICE', 'NEWS', 'INVESTORS'];
const PAGE_KEYS = ['Index', 'AboutUs', 'Team', 'Company', 'Games', 'Notice', 'News', 'NewDetail', 'Investors', 'NoticeDetail'];
const Notice_PAGE_KEYS = ['Index', 'News', 'Prospectus', 'Financial', 'Announcement', 'ECommunication'];
const C_ID = { NOTICE: 1, NEW: 2 };
const N_ID = { Prospectus: 1, Financial: 2, Announcement: 3, ECommunication: 5 };
const TYPE = { CN: 1, HK: 2, EN: 3 };
const ORG_IMAGES = [
    'https://download4.qcplay.com/site/organization-chart.png',
    'https://download4.qcplay.com/site/organization-chart-hk.png',
    'https://download4.qcplay.com/site/organization-chart-en.png'
];
const ORG_IMAGES_MOBILE = [
    'https://download4.qcplay.com/site/organization-chart-mobile.png',
    'https://download4.qcplay.com/site/organization-chart-mobile-hk.png',
    'https://download4.qcplay.com/site/organization-chart-mobile-en.png',
];
let DEFAULT_LANGUAGE = "zh_CN";
let cur_page = 'Index';
let notice_cur_page = 'Prospectus';
let notice_id = '';
let notice_page = 1;
let language_index = DEFAULT_LANGUAGE; //标识语言
let TYPE_MORE = '';
// 游戏配置
const GAME_CONFIG = [
    { id: 1, name: '最强蜗牛', introNum: 4 },
    { id: 2, name: '不思议迷宫', introNum: 2 },
    { id: 3, name: '提灯与地下城', introNum: 3 },
    { id: 4, name: '阿瑞斯病毒', introNum: 3 },
    { id: 5, name: '无尽大冒险', introNum: 2 },
    { id: 6, name: '使魔计划', introNum: 2 },
    { id: 7, name: '新仙剑奇侠传之挥剑问情', introNum: 2 },
    { id: 8, name: '魔卡少女樱：回忆钥匙', introNum: 2 },
    { id: 9, name: '时光旅行社', introNum: 2 },
    { id: 10, name: 'BloodAge', introNum: 1 },
    { id: 11, name: '肥鹅健身房', introNum: 1 },
    { id: 12, name: '阿瑞斯病毒2', introNum: 2 },
    { id: 14, name: '骑士冲呀', introNum: 1 },
    { id: 15, name: '迷途之光', introNum: 1 },
];

const obj = {
    // 首页
    index: function () {
        cur_page = PAGE_KEYS[0];
        this.common(NAV_KEYS[0]);
        this.fullVideo();
        this.closeFullVideo();
    },
    // 关于我们页面
    about_us: function () {
        cur_page = PAGE_KEYS[1];
        this.renderSubNav();
        this.common(NAV_KEYS[1]);
    },
    // 管理团队
    team: function () {
        cur_page = PAGE_KEYS[2];
        this.renderSubNav();
        this.common(NAV_KEYS[1]);
    },
    // 企业管治
    company: function () {
        cur_page = PAGE_KEYS[3];
        this.renderSubNav();
        this.common(NAV_KEYS[1]);
        [...ORG_IMAGES, ...ORG_IMAGES_MOBILE].forEach((url, idx) => {
            const img = new Image();
            img.src = url;
            img.onload = () => { console.info('预加载图片', idx + 1); };
        });
    },
    // 游戏大作
    games: function () {
        cur_page = PAGE_KEYS[4];
        this.common(NAV_KEYS[2]);
    },
    // 公告和邀请函页面
    notice: function () {
        cur_page = PAGE_KEYS[5];
        notice_cur_page = Notice_PAGE_KEYS[1];
        this.investSubNav();
        this.noticeBind();
        this.common(NAV_KEYS[3]);
    },
    notice_detail: function () {
        cur_page = PAGE_KEYS[9];
        this.common(NAV_KEYS[3]);
    },
    // 新闻页
    news: function () {
        cur_page = PAGE_KEYS[6];
        this.common(NAV_KEYS[4]);
        this.newsBind();
    },
    // 新闻详情页
    new_detail: function () {
        cur_page = PAGE_KEYS[7];
        this.common(NAV_KEYS[4]);
    },
    // 投资者查询
    investors: function () {
        cur_page = PAGE_KEYS[8];
        this.common(NAV_KEYS[5]);
    },


    // 公共方法
    common: function (key) {
        this.renderHeader(key);
        this.renderFooter();
        setTimeout(() => {
            $("body").addClass('achieve');
        }, 300);
        this.language();
        $(".header_2_nav_more").on('click', function () {
            if ($(".header_2_content").hasClass('open')) {
                $(".header_2_content").removeClass('open');
            } else {
                $(".header_2_content").addClass('open');
            }
        });
    },

    renderHeader: function (key) {
        const headerHTML1 =
            '<div class="header_2">' +
            '<div class="header_2_content">' +
            `<a href="${WEB_PROFIX}/index.html">` +
            '<div class="logo">' +
            '<img src="/images/qingci-logo-header-b87fd90832.png">' +
            '</div>' +
            '</a>' +
            '<div class="language">' +
            '<ul>' +
            '<li class="selected"><a href="javascript:;" data-type="zh_CN">简</a></li>' +
            '<li>|</li>' +
            '<li><a href="javascript:;" data-type="zh_HK">繁</a></li>' +
            '<li>|</li>' +
            '<li><a href="javascript:;" data-type="en_US">EN</a></li>' +
            '</ul>' +
            '</div>' +
            '<div class="header_2_nav">' +
            '<ul>' +
            `<li><a class="l_nav1" href="${WEB_PROFIX}/index.html">首页</a></li>` +
            `<li><a class="l_nav2" href="${WEB_PROFIX}/about_us.html">公司信息</a></li>` +
            `<li><a class="l_nav3" href="${WEB_PROFIX}/games.html">游戏大作</a></li>` +
            `<li><a class="l_nav4" href="${WEB_PROFIX}/notice.html?id=1">公告及通函</a></li>` +
            `<li><a class="l_nav5" href="${WEB_PROFIX}/news.html">新闻中心</a></li>` +
            `<li><a class="l_nav6" href="${WEB_PROFIX}/investors.html">投资者查询</a></li>` +
            '</ul>' +
            '</div>' +
            '<div class="header_2_nav_box">' +
            '<div class="header_2_nav_more">' +
            '<span class="icon-bar top"></span>' +
            '<span class="icon-bar middle"></span>' +
            '<span class="icon-bar bottom"></span>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>';
        const headerHTML2 =
            '<header class="header">' +
            '<div class="header-content">' +
            `<a class="logo" href="${WEB_PROFIX}/index.html">` +
            '<img src="/images/qingci-logo-header-b87fd90832.png" alt="qingci-game-logo">' +
            '</a>' +
            '<nav class="nav">' +
            '<ul>' +
            `<li class="${key === NAV_KEYS[0] ? 'active' : ''}"><a href="${key === NAV_KEYS[0] ? 'javascript:;' : WEB_PROFIX + '/index.html'}" class="l_nav1">首页</a></li>` +
            `<li class="${key === NAV_KEYS[1] ? 'active' : ''}"><a href="${key === NAV_KEYS[1] ? 'javascript:;' : WEB_PROFIX + '/about_us.html'}" class="l_nav2">公司信息</a></li>` +
            `<li class="product ${key === NAV_KEYS[2] ? 'active' : ''}">` +
            `<a href="${key === NAV_KEYS[2] ? 'javascript:;' : WEB_PROFIX + '/games.html'}" >` +
            '<span class="l_nav3">游戏大作</span><b></b>' +
            '</a>' +
            '<div class="game_list">' +
            '<dl>' +
            '<dd>' +
            '<a class="l_game_nav_1" href="https://snail.qingcigame.com/leading/" target="_blank">最强蜗牛</a>' +
            '</dd>' +
            '<dd>' +
            '<a class="l_game_nav_7" href="https://hjwq.qingcijoy.com/" target="_blank">新仙剑奇侠传之挥剑问情</a>' +
            '</dd>' +
            '<dd>' +
            '<a class="l_game_nav_3" href="https://tideng.qingcigame.com/" target="_blank">提灯与地下城</a>' +
            '</dd>' +
            '<dd>' +
            '<a class="l_game_nav_8" href="https://sakura.qingcijoy.com/" target="_blank">魔卡少女樱：回忆钥匙</a>' +
            '</dd>' +
            '<dd>' +
            '<a class="l_game_nav_11" href="https://fgg.qcplay.co.jp/" target="_blank">肥鹅健身房</a>' +
            '</dd>' +
            '<dd>' +
            '<a class="l_game_nav_10" href="https://bloodage.qcplay.co.kr/" target="_blank">BloodAge</a>' +
            '</dd>' +
            '<dd>' +
            '<a class="l_game_nav_2" href="https://gumballs.leiting.com/" target="_blank">不思议迷宫</a>' +
            '</dd>' +
            '<dd>' +
            '<a class="l_game_nav_4" href="https://ares.qingcigame.com/" target="_blank">阿瑞斯病毒</a>' +
            '</dd>' +
            '<dd>' +
            '<a class="l_game_nav_12" href="https://ares2.qingcigame.com/index.html" target="_blank">阿瑞斯病2</a>' +
            '</dd>' +
            '<dd>' +
            '<a class="l_game_nav_5" href="https://wjdmx.qingcigame.com/" target="_blank">无尽大冒险</a>' +
            '</dd>' +
            '<dd>' +
            '<a class="l_game_nav_15" href="https://mtzg.qingcijoy.com/" target="_blank">迷途之光</a>' +
            '</dd>' +
            '<dd>' +
            '<a class="l_game_nav_6" href="https://smjh.qingcigame.com/" target="_blank">使魔计划</a>' +
            '</dd>' +
            '<dd>' +
            '<a class="l_game_nav_9" href="https://sglxs.qingcitimes.com/" target="_blank">时光旅行社</a>' +
            '</dd>' +
            '<dd>' +
            '<a class="l_game_nav_14" href="https://qscy.qingcigame.com/" target="_blank">骑士冲呀</a>' +
            '</dd>' +
            '</dl>' +
            '</div>' +
            '</li>' +
            `<li class="${key === NAV_KEYS[3] ? 'active' : ''}"><a href="${key === NAV_KEYS[3] ? 'javascript:;' : WEB_PROFIX + '/notice.html?id=1'}" class="l_nav4">公告及通函</a></li>` +
            `<li class="${key === NAV_KEYS[4] ? 'active' : ''}"><a href="${key === NAV_KEYS[4] ? 'javascript:;' : WEB_PROFIX + '/news.html'}" class="l_nav5">新闻中心</a></li>` +
            `<li class="${key === NAV_KEYS[5] ? 'active' : ''}"><a href="${key === NAV_KEYS[5] ? 'javascript:;' : WEB_PROFIX + '/investors.html'}" class="l_nav6">投资者查询</a></li>` +
            '</ul>' +
            '</nav>' +
            '<div class="language">' +
            '<ul>' +
            '<li class="selected"><a href="javascript:;" data-type="zh_CN">简</a></li>' +
            '<li>|</li>' +
            '<li><a href="javascript:;" data-type="zh_HK">繁</a></li>' +
            '<li>|</li>' +
            '<li><a href="javascript:;" data-type="en_US">EN</a></li>' +
            '</ul>' +
            '</div>' +
            '</div>' +
            '</header>';
        $(".layout").prepend(headerHTML1 + headerHTML2);
    },

    renderSubNav: function () {
        const subNavList = [
            { key: PAGE_KEYS[1], name: '关于青瓷', class: 'l_sub_nav_1', link: `${WEB_PROFIX}/about_us.html` },
            { key: PAGE_KEYS[2], name: '管理团队', class: 'l_sub_nav_2', link: `${WEB_PROFIX}/team.html` },
            { key: PAGE_KEYS[3], name: '企业管治', class: 'l_sub_nav_3', link: `${WEB_PROFIX}/company.html` },
        ];
        const html = subNavList.reduce((r, item, idx) => {
            const li = `<li class="${item.key === cur_page ? 'active' : ''}"><a href="${item.key === cur_page ? 'javascript:;' : item.link}" class="${item.class}">${item.name}</a></li>`;
            if (idx >= subNavList.length - 1) {
                return r + li;
            } else {
                return r + li + '<li>|</li>';
            }
        }, '');
        $("#sub-nav").html(html);
    },

    investSubNav: function () {
        notice_id = window.location.href.split('?id=')[1];
        notice_page = 1;
        notice_cur_page = Notice_PAGE_KEYS[notice_id];
        $("#notice_list").html('');
        const subNavList = [
            { key: Notice_PAGE_KEYS[1], name: '公告与通函', class: 'i_sub_nav_3', link: `${WEB_PROFIX}/notice.html?id=1` },
            { key: Notice_PAGE_KEYS[4], name: '财务信息', class: 'i_sub_nav_2', link: `${WEB_PROFIX}/notice.html?id=4` },
            { key: Notice_PAGE_KEYS[5], name: '电子化通讯', class: 'i_sub_nav_4', link: `${WEB_PROFIX}/notice.html?id=5` },
            { key: Notice_PAGE_KEYS[3], name: '招股书', class: 'i_sub_nav_1', link: `${WEB_PROFIX}/notice.html?id=3` },
        ];
        const html = subNavList.reduce((r, item, idx) => {
            const li = `<li class="${item.key === notice_cur_page ? 'active' : ''}"><a href="${item.key === notice_cur_page ? 'javascript:;' : item.link}" class="${item.class}">${item.name}</a></li>`;
            if (idx >= subNavList.length - 1) {
                return r + li;
            } else {
                return r + li + '<li>|</li>';
            }
        }, '');
        $("#sub-nav").html(html);
    },

    renderFooter: function () {
        const footerHTML =
            '<footer class="footer">' +
            '<div class="center-content">' +
            '<div class="content-img">' +
            '<img src="/images/qingci-logo-footer-2b32c3f1fb.png" />' +
            '</div>' +
            '<div class="content-word">' +
            '<div class="statements">' +
            '<dl>' +
            '<dt class="l_follow_us">关注我们</dt>' +
            '<dd class="pc-wechat">' +
            '<span class="l_wechat">微信</span>' +
            '<div class="wechat-dialog">' +
            '<p class="l_follow_wechat">' +
            '关注"青瓷游戏"' +
            '</p>' +
            '<img src="/images/wechat-d8f951ab92.png" style="width:80px" /> ' +
            '</div>' +
            '</dd>' +
            '<dd>' +
            '<a class="l_follow_sina" href="https://m.weibo.cn/u/6996510335?" target="_blank">新浪微博</a>' +
            '</dd>' +
            '</dl>' +
            '<dl>' +
            '<dt class="l_join_us">加入我们</dt>' +
            '<dd>' +
            '<a class="l_recruitment" href="https://app.mokahr.com/apply/qingcishuma/41125" target="_blank">招聘</a>' +
            '</dd>' +
            '</dl>' +
            '</div>' +
            '<div class="info-list">' +
            '<p class="tip">' +
            '<span>抵制不良游戏&nbsp;&nbsp;拒绝盗版游戏&nbsp;&nbsp;注意自我保护&nbsp;&nbsp;谨防受骗上当&nbsp;&nbsp;</span>' +
            '<span>适度游戏益脑&nbsp;&nbsp;沉迷游戏伤身&nbsp;&nbsp;合理安排时间&nbsp;&nbsp;享受健康生活&nbsp;&nbsp;</span>' +
            '</p>' +
            '<p>' +
            '<span style="margin-left:0">闽网文[2018]7312-324号</span>' +
            '&nbsp;|&nbsp;' +
            '<span>ICP证闽B2-20191042 </span>' +
            '|&nbsp;' +
            '<span>' +
            '<a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备14007816号-1</a>' +
            '</span>' +
            '</p>' +
            '<p class="address">地址：厦门软件园二期望海路4号&nbsp;&nbsp;|&nbsp;&nbsp;开发者QcplayDigitalCo.,Ltd.</p>' +
            '<p>' +
            '<a href="http://sq.ccm.gov.cn/ccnt/sczr/service/business/emark/toDetail/cde8344a2f1b43548a824cbe43b4c027" target="__blank" style="margin-left:0;margin-right:0">' +
            '<img src="/images/wenhuajingying-e961048926.png" style="width:30px;height:30px;vertical-align:top;margin:-3px 5px -3px 0">' +
            '</a>' +
            '<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020302000990">' +
            '<img src="/images/police-5ad0425c22.png" alt="备案" style="margin:4px 0 -3px 0">' +
            '&nbsp;闽公网安备 35020302000990号&nbsp;' +
            '</a>' +
            '<span class="beian">&nbsp;&nbsp;Copyright&nbsp;&nbsp;©&nbsp;2012–2021&nbsp;&nbsp;QCPLAY&nbsp;&nbsp;</span><span class="beian">厦门青瓷数码技术有限公司&nbsp;&nbsp;版权所有</span>' +
            '</p>' +
            '</div>' +
            '<div class="info-list-mobile">' +
            '<p>闽网文[2018]7312-324号</p>' +
            '<p>ICP证闽B2-20191042 <a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备14007816号-1</a> <span>开发者QcplayDigitalCo.,Ltd.</span></p>' +
            '<p>Copyright&nbsp;&nbsp;©&nbsp;2012–2021&nbsp;&nbsp;QCPLAY&nbsp;&nbsp;<br>厦门青瓷数码技术有限公司&nbsp;&nbsp;版权所有</p>' +
            '<p>' +
            '<img src="/images/wenhuajingying-e961048926.png" style="width:30px;height:30px;vertical-align:top;margin:-3px 5px -3px 0">' +
            '<img src="/images/police-5ad0425c22.png" alt="备案" style="margin:4px 13px -3px 0">' +
            '<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020302000990">闽公网安备 35020302000990号</a>' +
            '</p>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</footer>';

        $(".layout").append(footerHTML);
    },

    // 多语言
    language: function () {
        const that = this;
        jQuery(document).ready(function () {
            const language_cookie = $.cookie('language');
            if (!language_cookie) {
                $.cookie('language', language_index);
            } else {
                language_index = language_cookie;
                $(`.language ul li a[data-type=${language_index}]`).parent().attr('class', 'selected').siblings().removeClass('selected');
            }

            // 新增是否en
            var urlString = window.location.href;
            var match = urlString.match(/[?&]language=([^&]+)/);
            var language = match ? match[1] : null;

            if (language === 'en_US') {
                $.cookie('language', 'en_US');
                that.loadProperties('en_US');
                $(`.language ul li a[data-type='en_US']`).parent().attr('class', 'selected').siblings().removeClass('selected');
            } else {
                that.loadProperties(language_index);
            }
        });
        $(".language ul li a").click(function () {
            if ($(this).parent().hasClass('selected')) {
                return false;
            }
            language_index = $(this).data('type') || DEFAULT_LANGUAGE;
            that.loadProperties(language_index);
            $.cookie('language', language_index);
            $(`.language ul li a[data-type=${language_index}]`).parent().attr('class', 'selected').siblings().removeClass('selected');
        });
    },

    // 调用多语言
    loadProperties: function (type) {
        const that = this;
        jQuery.i18n.properties({
            name: 'strings', // 资源文件名称  
            path: 'language/', // 资源文件所在目录路径  
            mode: 'map', // 模式：变量或 Map  
            language: type, // 对应的语言  
            cache: false,
            encoding: 'UTF-8',
            callback: function () { // 回调方法  
                $(".layout").attr('class', `layout ${type}`);
                that.translateHeader();
                that[`translate${cur_page}`](type);
                that.translateFooter();
            }
        });
    },

    // 翻译头部
    translateHeader: function () {
        $('.l_nav1').html($.i18n.prop('home'));
        $('.l_nav2').html($.i18n.prop('companyInformation'));
        $('.l_nav3').html($.i18n.prop('gameList'));
        $('.l_nav4').html($.i18n.prop('notice'));
        $('.l_nav5').html($.i18n.prop('news'));
        $('.l_nav6').html($.i18n.prop('investorEnquiries'));

        GAME_CONFIG.forEach((item) => {
            const { id } = item;
            $(`.l_game_nav_${id}`).html($.i18n.prop(`gameNav${id}`));
        });
    },

    // 翻译底部
    translateFooter: function () {
        $(".l_follow_us").html($.i18n.prop('followUs'));
        $(".l_wechat").html($.i18n.prop('wechatName'));
        $(".l_follow_wechat").html($.i18n.prop('followWechat'));
        $(".l_follow_sina").html($.i18n.prop('followSina'));
        $(".l_join_us").html($.i18n.prop('joinUs'));
        $(".l_recruitment").html($.i18n.prop('Recruitment'));
    },

    // 翻译首页
    translateIndex: function () {
        $(".page_title").html($.i18n.prop("homeTitle") + "-" + $.i18n.prop("pageSuffix"));
        $('.l_company_name').html($.i18n.prop('companyName'));
        $('.l_intro').html($.i18n.prop('intro'));
    },

    // 翻译 关于我们
    translateAboutUs: function () {
        $(".page_title").html($.i18n.prop("companyInformation") + "-" + $.i18n.prop("pageSuffix"));
        $('.l_sub_nav_1').html($.i18n.prop('subNav1'));
        $('.l_sub_nav_2').html($.i18n.prop('subNav2'));
        $('.l_sub_nav_3').html($.i18n.prop('subNav3'));

        $('.company_intro').html($.i18n.prop('companyIntro'));
    },

    // 翻译团队管理
    translateTeam: function () {
        $(".page_title").html($.i18n.prop("companyInformation") + "-" + $.i18n.prop("pageSuffix"));
        $('.l_sub_nav_1').html($.i18n.prop('subNav1'));
        $('.l_sub_nav_2').html($.i18n.prop('subNav2'));
        $('.l_sub_nav_3').html($.i18n.prop('subNav3'));

        $(".l_member_1_name").html($.i18n.prop('member1Name'));
        $(".l_member_1_position").html($.i18n.prop('member1Position'));
        $(".l_member_1_intro_1").html($.i18n.prop('member1Intro1'));
        $(".l_member_1_intro_2").html($.i18n.prop('member1Intro2'));

        $(".l_member_2_name").html($.i18n.prop('member2Name'));
        $(".l_member_2_position").html($.i18n.prop('member2Position'));
        $(".l_member_2_intro_1").html($.i18n.prop('member2Intro1'));

        $(".l_member_3_name").html($.i18n.prop('member3Name'));
        $(".l_member_3_position").html($.i18n.prop('member3Position'));
        $(".l_member_3_intro_1").html($.i18n.prop('member3Intro1'));

        $(".l_member_4_name").html($.i18n.prop('member4Name'));
        $(".l_member_4_position").html($.i18n.prop('member4Position'));
        $(".l_member_4_intro_1").html($.i18n.prop('member4Intro1'));

        $(".l_member_5_name").html($.i18n.prop('member5Name'));
        $(".l_member_5_position").html($.i18n.prop('member5Position'));
        $(".l_member_5_intro_1").html($.i18n.prop('member5Intro1'));
    },

    // 翻译公司治理
    translateCompany: function (type) {
        $(".page_title").html($.i18n.prop("companyInformation") + "-" + $.i18n.prop("pageSuffix"));
        $('.l_title').html($.i18n.prop('title1'));

        $('.l_sub_nav_1').html($.i18n.prop('subNav1'));
        $('.l_sub_nav_2').html($.i18n.prop('subNav2'));
        $('.l_sub_nav_3').html($.i18n.prop('subNav3'));

        $('.l_organization').html($.i18n.prop('organizationTitle'));
        switch (type) {
            case 'en_US':
                $(".organization_chart").attr('src', ORG_IMAGES[2]);
                $(".organization_chart_mobile").attr('src', ORG_IMAGES_MOBILE[2]);
                break;
            case 'zh_CN':
                $(".organization_chart").attr('src', ORG_IMAGES[0]);
                $(".organization_chart_mobile").attr('src', ORG_IMAGES_MOBILE[0]);
                break;
            case 'zh_HK':
                $(".organization_chart").attr('src', ORG_IMAGES[1]);
                $(".organization_chart_mobile").attr('src', ORG_IMAGES_MOBILE[1]);
                break;
            default:
                console.error('无效语言:', type);
                break;
        }
    },

    // 翻译游戏大作
    translateGames: function (language_type) {
        $(".page_title").html($.i18n.prop("gameList") + "-" + $.i18n.prop("pageSuffix"));
        $('.l_title').html($.i18n.prop('title2'));

        GAME_CONFIG.forEach((info) => {

            const { id, introNum } = info;
            // 游戏大作页 - 标题和介绍
            let mobileIntroHtml = `${$.i18n.prop('game' + id + 'Title')}，`;
            // pc端
            $(`.l_game_${id}_title`).html($.i18n.prop(`game${id}Title`));
            for (let i = 1; i <= introNum; i++) {
                $(`.l_game_${id}_intro_${i}`).html($.i18n.prop(`game${id}Intro${i}`));
                mobileIntroHtml += ($.i18n.prop(`game${id}Intro${i}`) + ';');
            }
            // 移动端
            $(`.l_game_name_${id}`).html($.i18n.prop(`gameName${id}`));
            $(`.l_game_intro_mobile_${id}`).html(mobileIntroHtml);
        });

        function sakuraChange(language_type) {
            // 根据语言类型设置图片和链接
            var settings = {
                'zh_CN': {
                    'logoSrc': '/images/game-logo-8-fd75677af3.png',
                    'href': 'https://sakura.qingcijoy.com/'
                },
                'default': {
                    'logoSrc': '/images/game-logo-8-2-5007df772b.png',
                    'href': 'https://sakura.snell.com.tw/'
                }
            };

            // 获取当前语言的设置，如果没有匹配到则使用默认设置
            var currentSetting = settings[language_type] || settings['default'];

            // 应用设置
            $('.game-8-logo').attr('src', currentSetting.logoSrc);
            $('.game-8-a').attr('href', currentSetting.href);
        } sakuraChange(language_type);
    },

    // 翻译公告
    translateNotice: function (language_type) {
        notice_page = 1;
        $('.load-more').attr('data', 2);
        $("#notice_list").html('');
        $('.more_btn').html($.i18n.prop('moreBtn'));

        $('.i_sub_nav_1').html($.i18n.prop('investSubNav1'));
        $('.i_sub_nav_2').html($.i18n.prop('investSubNav2'));
        $('.i_sub_nav_3').html($.i18n.prop('investSubNav3'));
        $('.i_sub_nav_4').html($.i18n.prop('investSubNav4'));

        $(".page_title").html($.i18n.prop("notice") + "-" + $.i18n.prop("pageSuffix"));
        $('.l_title').html($.i18n.prop('title3'));
        let type = TYPE.CN;
        TYPE_MORE = TYPE.CN;
        if (language_type === 'en_US') {
            type = TYPE.EN;
            TYPE_MORE = TYPE.EN;
        } else if (language_type === 'zh_HK') {
            type = TYPE.HK;
            TYPE_MORE = TYPE.HK;
        }
        this.getNotices(type);
    },

    // 翻译公告详情
    translateNoticeDetail: function () {
        $(".page_title").html($.i18n.prop("notice") + "-" + $.i18n.prop("pageSuffix"));
        const id = location.search.replace('?id=', '');
        if (!id) {
            layer.msg("页面错误！");
        } else {
            this.getDetail(id);
        }

        $(".language").remove();
        $('.l_title').html($.i18n.prop('title3'));
    },

    // 翻译新闻
    translateNews: function (language_type) {
        notice_page = 1;
        $('.load-more').attr('data', 2);
        $("#new_list").html('');
        $('.more_btn').html($.i18n.prop('moreBtn'));

        $(".page_title").html($.i18n.prop("news") + "-" + $.i18n.prop("pageSuffix"));
        $('.l_title').html($.i18n.prop('title4'));
        let type = TYPE.CN;
        TYPE_MORE = TYPE.CN;
        if (language_type === 'en_US') {
            type = TYPE.EN;
            TYPE_MORE = TYPE.EN;
        } else if (language_type === 'zh_HK') {
            type = TYPE.HK;
            TYPE_MORE = TYPE.HK;
        }
        this.getNews(type);
    },

    // 翻译新闻详情
    translateNewDetail: function () {
        $(".page_title").html($.i18n.prop("news") + "-" + $.i18n.prop("pageSuffix"));
        const id = location.search.replace('?id=', '');
        if (!id) {
            layer.msg("页面错误！");
        } else {
            this.getDetail(id);
        }

        $(".language").remove();
        $('.l_title').html($.i18n.prop('title4'));
    },

    // 翻译投资人查询
    translateInvestors: function () {
        $(".page_title").html($.i18n.prop("investorEnquiries") + "-" + $.i18n.prop("pageSuffix"));
        $('.l_title').html($.i18n.prop('title5'));
        $('.l_company_name').html($.i18n.prop('companyName'));
        $('.l_investor_title_1').html($.i18n.prop('investor_title_1'));
        $('.l_investor_title_2').html($.i18n.prop('investor_title_2'));
        $('.l_address').html($.i18n.prop('address'));
        $('.l_postCode').html($.i18n.prop('postCode'));
        // $('.l_email').html($.i18n.prop('EMail'));
    },


    // 首页视频全屏
    fullVideo: function () {
        $(".video-mask").on('click', function () {
            const bodyDom = $("body");
            if (!bodyDom.hasClass('full_screen')) {
                bodyDom.addClass('full_screen');
                $("#video").prop('muted', false);
            }
        });
    },

    closeFullVideo: function () {
        $(".close-full-video").on('click', function (e) {
            const bodyDom = $("body");
            console.log('1111111', bodyDom.hasClass('full_screen'))
            if (bodyDom.hasClass('full_screen')) {

                $("body").attr({ class: 'achieve' });
                $("#video").prop('muted', true);
            }
        });
    },

    // api请求方法
    getNotices: function (type) {
        const _this = this;
        type = type || TYPE.CN;
        this.onAjax({
            url: `${SEVER_URL}/site/article?c_id=${notice_id}&type=${type}&page=${notice_page}`,
            type: 'GET',
            success: (result) => {
                let _list = result.data.list;
                _this.renderNoticesDom(_list.data, _list.last_page);
                _this.loadMoreState(_list.data.length, _list.last_page);
            },
        });
    },

    noticeBind: function () {
        const _this = this;
        $('.load-more').on('click', function (event) {
            notice_page = $(this).attr('data');
            $(this).attr('data', Number(notice_page) + 1);
            _this.getNotices(TYPE_MORE);
        });
    },

    renderNoticesDom: function (data, last_page) {
        const html = data.reduce((r, item) => {
            const articleUrl = item.article_url || `${WEB_PROFIX}/notice_detail.html?id=${item.id}`;
            const target = item.article_url ? "_blank" : "";
            r = `${r}<li><a href="${articleUrl}" target="${target}">
              <span class="new_title">${item.article_title}</span>
              <span class="new_time">${item.release_time}</span>
            </a>
          </li>`;
            return r;
        }, '');
        $("#notice_list").append(html);
    },

    getNews: function (type) {
        const _this = this;
        type = type || TYPE.CN;
        this.onAjax({
            url: `${SEVER_URL}/site/article?c_id=${C_ID.NEW}&type=${type}&page=${notice_page}`,
            type: 'GET',
            success: (result) => {
                let _list = result.data.list;
                _this.renderNewsDom(_list.data, _list.last_page);
                _this.loadMoreState(_list.data.length, _list.last_page);
            },
        });
    },

    newsBind: function () {
        const _this = this;
        $('.load-more').on('click', function (event) {
            notice_page = $(this).attr('data');
            $(this).attr('data', Number(notice_page) + 1);
            _this.getNews(TYPE_MORE);
        });
    },

    loadMoreState: function (length, last_page, key) {
        const _this = this;
        if (length >= 10) {
            $('.load-more').show();
        }
        if ($('.load-more').attr('data') > last_page) {
            $('.load-more').hide();
        }
    },

    renderNewsDom: function (data, last_page) {
        const html = data.reduce((r, item) => {
            // const createDate = new Date(item.created_at);
            // const year = createDate.getFullYear(),
            //     date = createDate.getDate(),
            //     month = createDate.getMonth() + 1;
            const articleUrl = item.article_url || `${WEB_PROFIX}/new_detail.html?id=${item.id}`;
            const target = item.article_url ? "_blank" : "";
            r = `${r}<li><a href="${articleUrl}" target="${target}"><span class="new_title">${item.article_title}</span><span class="new_time">${item.release_time}</span></a></li>`;
            return r;
        }, '');

        $("#new_list").append(html);
    },

    getDetail: function (id) {
        const _this = this;
        this.onAjax({
            url: `${SEVER_URL}/site/article/${id}`,
            type: 'GET',
            success: (result) => {
                _this.renderDetail(result.data.list);
            },
        });
    },

    renderDetail: function (info) {
        const createDate = new Date(info.created_at);
        const year = createDate.getFullYear(),
            date = createDate.getDate(),
            month = createDate.getMonth() + 1;
        const config = {
            [PAGE_KEYS[7]]: { url: `${WEB_PROFIX}/news.html`, name: "新闻中心", title: $.i18n.prop('breadCrumbNew') },
            [PAGE_KEYS[9]]: { url: `${WEB_PROFIX}/notice.html?id=1`, name: "投资者关系", title: $.i18n.prop('breadCrumbNotice') },
        }
        const breadCrumb = config[cur_page] || {};
        $("#bread-crumb").html(`<a class="l_bread_crumb_home" href="${WEB_PROFIX}/index.html">首页</a> / <a class="l_bread_crumb_title_2" href="${breadCrumb.url}">${breadCrumb.name}</a> / <span>${info.article_title}</span>`);
        $("#create-date").html(`${year}-${month > 9 ? month : '0' + month}-${date}`);
        $("#article-title").html(info.article_title);
        $("#article-content").html(info.article_content);
        $('.l_bread_crumb_home').html($.i18n.prop('breadCrumbHome'));
        $('.l_bread_crumb_title_2').html(breadCrumb.title);
    },


    // 简易封装常用ajax
    onAjax: function (options) {
        const { url, type, success, error, data } = options;
        $.ajax({
            url: url,
            type: type,
            data: data,
            success: function (json) {
                if (json.code !== 200) {
                    var message = json.message || "服务器错误";
                    layer.msg(message);
                    return false;
                }
                success && success(json);
            },
            error: function (err) {
                layer.msg("服务器错误");
                error && error(err);
            }
        });
    },
};